import React, { useEffect, useState } from 'react';
import './agreementPopup.css'
import agreementText from '../agreementText';


const AgreementPopup = ({ onAccept, onDecline }) => {
  const [animateClass, setAnimateClass] = useState('');

  useEffect(() => {
    const popup = document.querySelector('.agreement-popup-overlay');
    if (popup) {
      setTimeout(() => {
        popup.classList.add('show');
      }, 100); // Задержка для начала анимации открытия
    }
  }, []);

  const handleDecline = () => {
    const popup = document.querySelector('.agreement-popup-overlay');
    if (popup) {
      popup.classList.remove('show');
      setTimeout(() => {
        onDecline();
      }, 400); // Длительность анимации закрытия
    }
  };

  const handleAccept = () => {
    const popup = document.querySelector('.agreement-popup-overlay');
    if (popup) {
      popup.classList.remove('show');
      setTimeout(() => {
        onAccept();
      }, 400); // Длительность анимации закрытия
    }
  };

  return (
    <div className={`agreement-popup-overlay ${animateClass}`}>
      <div className="agreement-popup">
        <h2>Пользовательское соглашение</h2>
        <div className="agreement-content">
            <div className="modal-popup-content" dangerouslySetInnerHTML={{ __html: agreementText }}>
            </div>
        </div>
        <div className="agreement-buttons">
          <button className="accept-button" onClick={handleAccept}>Принять</button>
          <button className ="decline-button" onClick={handleDecline}>Отклонить</button>
        </div>
      </div>
    </div>
  );
};

export default AgreementPopup;
