// src/pages/AdminPanel.js
import React, { useState, useEffect } from 'react';
import './css/adminpanel.css';

const AdminPanel = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [token, setToken] = useState('');
  const [stats, setStats] = useState({ visits: 0, donations: 0, conversionRate: 0 });
  const [donations, setDonations] = useState([]);
  const [showDonations, setShowDonations] = useState(false);

  useEffect(() => {
    if (isLoggedIn) {
      fetchStats();
      if (showDonations) {
        fetchDonations();
      }
    }
  }, [isLoggedIn, showDonations]);

  const fetchStats = async () => {
    try {
      const response = await fetch('https://9avalon.ru/api/payment/stats', {
        headers: {
          'Authorization': token
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setStats(data);
    } catch (error) {
      console.error('Ошибка при получении статистики: ', error);
    }
  };

  const fetchDonations = async () => {
    try {
      const response = await fetch('https://9avalon.ru/api/payment/all-donations', {
        headers: {
          'Authorization': token
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setDonations(data);
      setShowDonations(true);
    } catch (error) {
      console.error('Ошибка при получении данных о пожертвованиях: ', error);
    }
  };

  const handleRefresh = () => {
    fetchStats();
    if (showDonations) {
      fetchDonations();
    }
  };

  const toggleDonations = () => {
    if (showDonations) {
      setShowDonations(false);
    } else {
      fetchDonations();
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://9avalon.ru/api/auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });
      if (!response.ok) {
        throw new Error('Ошибка при входе');
      }
      const data = await response.json();
      setToken(`Bearer ${data.token}`);
      setIsLoggedIn(true);
    } catch (error) {
      console.error('Ошибка при входе:', error);
    }
  };

  if (!isLoggedIn) {
    return (
      <div className="login-form">
        <form onSubmit={handleLogin}>
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button type="submit">Login</button>
        </form>
      </div>
    );
  }

  return (
    <div className="admin-panel">
      <div className="stats">
        <p>Посещений: {stats.visits}</p>
        <p>Донатов: {stats.donations}</p>
        <p>Конверсия: {stats.conversionRate.toFixed(2)}%</p>
      </div>
      <button onClick={handleRefresh}>Обновить информацию</button>
      <button onClick={toggleDonations}>
        {showDonations ? 'Скрыть донаты' : 'Показать донаты'}
      </button>
      {showDonations && (
        <div className="donations-table-wrapper">
          <table className="donations-table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Сумма</th>
                <th>Дата</th>
              </tr>
            </thead>
            <tbody>
              {donations.map(donation => (
                <tr key={donation.id}>
                  <td>{donation.id}</td>
                  <td>{donation.amount}</td>
                  <td>{new Date(donation.created_at).toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default AdminPanel;
