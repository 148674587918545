// src/components/Footer.js
import React, { useState } from 'react';
import './css/footer.css';
import AgreementModal from './css/components/agreementModal/agreementModal';

const Footer = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);




  const openModal = (e) => {
    e.preventDefault(); // предотвратить добавление # в URL
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };



  return (
    <div className="footer">
      <div className="footer-container">
      <button onClick={toggleExpand} className="expand-button">
          {isExpanded ? '▼' : '▲'}
          </button>
        <div className={`footer-links ${isExpanded ? 'expanded' : ''}`}>
            <p>ИП Межевой А. П. ИНН:380414467293</p>
            <a href="#!" onClick={openModal}>Пользовательское соглашение</a>
            <a href="mailto:iyrishi@mail.ru" className="contact-link">Контакты</a> 
            <span className="footer-span"></span>     
          </div>
          <AgreementModal isOpen={isModalOpen} onRequestClose={closeModal} />
      </div>
    </div>
    
  );
};

export default Footer;
