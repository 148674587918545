// src/pages/HomePage.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import useMousePositionGradient from './hooks/useMousePositionGradient';
import './css/homepage.css';

const HomePage = () => {
  const navigate = useNavigate();
  useMousePositionGradient('.centre-button');


  useEffect(() => {
    setTimeout(() => {
      const content = document.querySelector('.home-page');
      if (content) {
        content.classList.add('content-visible');
      }
    }, 100); // Добавляем небольшую задержку для плавного появления
  }, []);

  const handleClick = () => {
    navigate('/form');
  };

  
  return (
  <div className="home-page content-hidden">
    <button className="centre-button" onClick={handleClick}>
          Подарить жизнь
      </button>
      <div className="text-container">
      <div className ="text-under">
      <p>УКРЫВШИСЬ ПОД МОСТОМ, <br />
      СПИТ ЗИМНЕЙ СНЕЖНОЙ НОЧЬЮ <br />
      БЕЗДОМНОЕ ДИТЯ <br /><br />
      КОБАЯСИ ИССА <br />
      <span></span>
      </p>
      </div>
    </div>
  </div>
  );
};

export default HomePage;


  