import React, { useState, useEffect, useRef} from 'react';
import './css/progressbar.css';

const ProgressBar = () => {
    const [progress, setProgress] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0); // Добавляем состояние для общей суммы
    const [particles, setParticles] = useState([]);
    const [showTooltip, setShowTooltip] = useState(false); // Состояние для отображения подсказки
    const maxParticles = 200; // Максимальное количество звездочек
    const previousTotalAmount = useRef(0); // Сохранение предыдущей суммы пожертвований



    // Функция для получения текущей суммы пожертвований
    const fetchProgress = async () => {
        try {
            const response = await fetch('https://9avalon.ru/api/payment/progress'); // Полный URL к API
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            const totalAmount = data.total ? parseFloat(data.total) : 0;
            setTotalAmount(totalAmount); // Обновляем общую сумму
            if (totalAmount !== previousTotalAmount.current) { // Проверка на изменение суммы пожертвований
                const progressPercentage = Math.min((totalAmount / 2000000) * 100, 100); // Линейная шкала
                setProgress(progressPercentage);
                updateParticles(totalAmount);
                previousTotalAmount.current = totalAmount; // Обновление сохраненной суммы пожертвований
            }
        } catch (error) {
            console.error('Ошибка при получении данных о пожертвованиях: ', error);
        }
    };


    // Обновление частиц в зависимости от количества звездочек
    const updateParticles = (totalAmount) => {
        const numParticles = Math.max(Math.floor(totalAmount / 100), 1); // Минимум одна звездочка
        const limitedParticles = Math.min(numParticles, maxParticles); // Ограничение количества звездочек

        const particleSpacingX = 100 / limitedParticles;

        const newParticles = Array.from({ length: limitedParticles }, (_, index) => {
            const left = index * particleSpacingX;
            const top = Math.random() * 100;

            return {
                id: index,
                style: {
                    left: `${left}%`,
                    top: `${top}%`,
                    animationDuration: `${Math.random() * 2 + 2}s`,
                    animationDelay: `${Math.random() * 0.5}s`,
                    opacity: Math.min(0.3 + (totalAmount / 2000000), 1) // Яркость пропорциональна сумме
                },
            };
        });

        setParticles(newParticles);
    };



    // Вызов fetchProgress при загрузке компонента
    useEffect(() => {
        fetchProgress();
        const interval = setInterval(fetchProgress, 10000); // Обновление каждые 10 секунд
        return () => clearInterval(interval); // Очистка интервала при размонтировании компонента
    }, []);

    return (
        <div className="progress-container-wrapper">
            <div 
                className="progress-container"
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
            >
                {particles.map((particle) => (
                    <div key={particle.id} className="progress-particle" style={particle.style} />
                ))}
            </div>
            <div className={`tooltip ${showTooltip ? 'visible' : ''}`}>
                    {totalAmount.toLocaleString('ru-RU')} ₽
                </div>
        </div>
    );
};

export default ProgressBar;