// src/components/AgreementModal.js
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import './agreementModal.css';
import agreementText from '../agreementText';


const AgreementModal = ({ isOpen, onRequestClose }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [animateClass, setAnimateClass] = useState('');

  useEffect(() => {
    if (isOpen) {
      setIsVisible(true);
      setTimeout(() => {
        setAnimateClass('show');
      }, 200); // Задержка для начала анимации открытия
    } else {
      setAnimateClass('hide');
      setTimeout(() => {
        setIsVisible(false);
      }, 200); // Длительность анимации закрытия
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isVisible}
      onAfterOpen={() => {
        const modal = document.querySelector('.agreement-modal');
        if (modal) modal.classList.add('show');
      }}
      onAfterClose={() => {
        const modal = document.querySelector('.agreement-modal');
        if (modal) modal.classList.remove('show');
      }}
      onRequestClose={onRequestClose}
      contentLabel="Пользовательское соглашение"
      className={`agreement-modal ${animateClass}`}
      overlayClassName="agreement-modal-overlay"
    >
      <h2>ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ</h2>
      <div className="modal-content" dangerouslySetInnerHTML={{ __html: agreementText }}></div>
      <div className="button-container">
      <button className="close-button" onClick={onRequestClose}>Закрыть</button>
      </div>
    </Modal>
  );
};

export default AgreementModal;
