import React from 'react';
import './css/header.css';

const Header = () => {
  return (
    <header className="header">
      Авалон
    </header>
  );
};

export default Header;