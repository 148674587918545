import { useEffect } from 'react';

const useMousePositionGradient = (selector) => {
  useEffect(() => {
    const element = document.querySelector(selector);

    if (!element) return;

    const handleMouseMove = (event) => {
      const rect = element.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
      element.style.setProperty('--x', `${x}px`);
      element.style.setProperty('--y', `${y}px`);
    };

    element.addEventListener('mousemove', handleMouseMove);

    return () => {
      element.removeEventListener('mousemove', handleMouseMove);
    };
  }, [selector]);
};

export default useMousePositionGradient;
