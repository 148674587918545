// src/components/agreementText.js

const agreementText = `
<p>Российская Федерация<br>28 июня 2024 г.</p>

<h3>1. ОБЩИЕ ПОЛОЖЕНИЯ</h3>
<p>Настоящее Пользовательское соглашение (далее по тексту - «Пользовательское соглашение», «Соглашение») регулирует отношения между Пользователем и Администрацией при использовании интернет-сайта, расположенного в сети Интернет по адресу: https://9avalon.ru, включая все уровни домена, (далее по тексту – «Сайт»).</p>
<p>Используя Сайт, Пользователь заявляет и гарантирует, что он достиг как минимум 16-летнего, либо является должным образом зарегистрированным индивидуальным предпринимателем либо является должным образом уполномоченным представителем юридического лица.</p>
<p>Использование Сайта Посетителем означает согласие с настоящим Пользовательским соглашением.</p>
<p>Начало использования Сайта означает подтверждение свободного волеизъявления Пользователя, направленного на полное и безоговорочное принятие условий настоящего Пользовательского соглашения и присоединение к нему на приведенных ниже условиях.</p>
<p>В случае несогласия с условиями настоящего Пользовательского соглашения, а также Посетитель должен прекратить использование Сайта.</p>

<h3>2. ОСНОВНЫЕ ПОНЯТИЯ, ИСПОЛЬЗУЕМЫЕ В ПОЛЬЗОВАТЕЛЬСКОМ СОГЛАШЕНИИ</h3>
<p>Для целей настоящего Пользовательского соглашения нижеприведенные термины и определения используются в следующем значении.</p>
<ul>
    <li><strong>Администрация</strong> — гражданин Российской Федерации Межевой Алексей Павлович или другие уполномоченные им лица, управляющие Сайтом. Администрация после акцепта Пользователем Оферты выступает как Одаряемый.</li>
    <li><strong>Акцепт Оферты</strong> - полное и безоговорочное принятие Оферты путем осуществления определенных в ней действий. Акцептом Оферты заключается Договор.</li>
    <li><strong>Баннер</strong> - графический или текстово-графический прямоугольный или квадратный блок информации, размещаемый на веб-странице.</li>
    <li><strong>Браузер</strong> (также веб-браузер) - прикладное программное обеспечение для просмотра веб-страниц, содержания веб-документов, компьютерных файлов и их каталогов, управления веб-приложениями, а также для решения других задач.</li>
    <li><strong>Договор</strong> - соглашение двух или нескольких лиц об установлении, изменении или прекращении гражданских прав и обязанностей.</li>
    <li><strong>Доменное имя</strong> (также домен) - обозначение символами, предназначенное для адресации сайтов в сети Интернет в целях обеспечения доступа к информации, размещенной в сети Интернет.</li>
    <li><strong>Контент</strong> - текстовые, графические, аудио- и видеоматериалы, творческие и дизайнерские разработки, логотипы, слоганы, элементы фирменного стиля, планы, рисунки, эскизы, макеты, чертежи, программы для электронных вычислительных машин и т. д.</li>
    <li><strong>Оферта</strong> - публичное предложение, адресованное любому физическому лицу, индивидуальному предпринимателю или юридическому лицу, достаточно определенно выражающее намерение исполнителя, считать себя заключившим договор с адресатом, которым будет принято предложение, содержащее существенные условия Договора.</li>
    <li><strong>Персональные данные</strong> – любая информация, относящаяся прямо или косвенно к определенному или определяемому Пользователю Сайта.</li>
    <li><strong>Пользователь</strong> - лицо, совершившее вход на Сайт. Пользователь после акцепта Оферты выступает как Даритель.</li>
    <li><strong>Сайт</strong> (также интернет-сайт, веб-сайт) – совокупность графических и информационных материалов, а также программ для электронных-вычислительных машин и баз данных, обеспечивающих их доступность в сети Интернет.</li>
    <li><strong>Субдомен</strong> - веб-страница, расположенная на домене третьего уровня, принадлежащая Сайту, а также другие временные страницы, внизу который указана контактная информация Администрации.</li>
    <li><strong>Сторона</strong> (Стороны) - Администратор и Пользователь.</li>
    <li>Термины, не определенные в настоящем Пользовательском соглашении, толкуются в соответствии с текстом Пользовательского соглашения. В случае отсутствия однозначного толкования термина в тексте Пользовательского соглашения следует руководствоваться толкованием термина, определенным: в первую очередь – действующим законодательством Российской Федерации, во вторую очередь - указанным на Сайте, затем - сложившимся (общеупотребительным) значением.</li>
</ul>

<h3>3. ПУБЛИЧНАЯ ОФЕРТА О ЗАКЛЮЧЕНИИ ДОГОВОРА ДАРЕНИЯ</h3>
<p>Настоящая публичная оферта (Оферта) является предложением гражданина Российской Федерации Межевого Алексея Павловича (далее – Одаряемый) заключить договор дарения (далее - Договор) с любым физическим лицом, индивидуальным предпринимателем или юридическим лицом, которое примет Оферту (далее — Даритель), на условиях, предусмотренных ниже.</p>
<p>Оферта является публичной офертой в соответствии с п. 2 ст. 437 Гражданского кодекса Российской Федерации.</p>
<p>Целью предложения Оферты является создание Одаряемым продукта с книгой-предысторией.</p>
<p>Согласно Договору Даритель безвозмездно передает в собственность Одаряемому денежные средства в размере, определяемом Дарителем.</p>
<p>Договор заключается путем акцепта Оферты Дарителем.</p>
<p>Оферта может быть акцептована Дарителем путем перечисления Дарителем денежных средств (Дара) в пользу Одаряемого через форму оплаты на Сайте.</p>
<p>Даритель выражает свое согласие с тем, что факт выполнения определенных действий на Сайте, выполнение команд через клиентский интерфейс Сайта, как то: нажатие кнопок, клики по ссылкам, совершение платежа и иные подобные действия означают его волеизъявление в отношении акцепта Оферты.</p>
<p>Датой акцепта Оферты и, соответственно, датой заключения Договора является дата поступления денежных средств от Дарителя на счет Одаряемого.</p>
<p>Расходы, связанные с дарением, в том числе на уплату комиссий платежных сервисов несет Даритель.</p>
<p>Совершая действия, предусмотренные данной Офертой, Даритель подтверждает, что ознакомлен с условиями и текстом настоящей Оферты, осознает значение своих действий, имеет полное право на их совершение и полностью принимает условия настоящей Оферты.</p>
<p>Даритель заявляет и гарантирует, что владеет предметом дарения на законных основаниях и имеет законные основания распоряжаться им.</p>
<p>Одаряемый в случае необходимости уплачивает все налоги и сборы, предусмотренные действующим законодательством Российской Федерации.</p>

<h3>4. ПРАВА И ОБЯЗАННОСТИ СТОРОН</h3>
<ul>
    <li>Администрация имеет право по своему усмотрению создавать, изменять и удалять информацию на Сайте.</li>
    <li>Администрация имеет право по своему усмотрению ограничивать доступ к любой информации на Сайте.</li>
    <li>Пользователь вправе использовать функции Сайта в соответствии с его целевым предназначением.</li>
    <li>Пользователь имеет право производить поиск информации на Сайте и получать информацию на Сайте.</li>
    <li>Пользователь вправе в любой момент отказаться прекратить использование Сайта.</li>
    <li>Пользователь обязуется не копировать информацию с Сайта на другие сайты.</li>
    <li>Пользователь обязуется не предпринимать действий, способных нарушить работу Сайта.</li>
    <li>Пользователь обязуется не использовать скрипты (программы) для автоматизированного сбора информации и (или) взаимодействия с Сайтом и его сервисами.</li>
</ul>

<h3>5. ИНТЕЛЛЕКТУАЛЬНАЯ СОБСТВЕННОСТЬ</h3>
<p>Администрация гарантирует, что условия использования Сайта полностью соответствуют действующему законодательству Российской Федерации в сфере интеллектуальной собственности, авторских и смежных прав.</p>
<p>Любое использование Сайта или Контента, кроме разрешенного в настоящем Пользовательском соглашении или в случае явно выраженного согласия правообладателя на такое использование, без предварительного письменного разрешения правообладателя, категорически запрещено.</p>
<p>Если иное явным образом не установлено в настоящем Пользовательском соглашении, ничто в настоящем Пользовательском соглашении не может быть рассмотрено как передача исключительных прав на Контент.</p>

<h3>6. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</h3>
<p>Администрация предоставляет возможность пользоваться Сайтом «как есть» («as is») и не несет ответственности за любые убытки, включая упущенную выгоду, связанную с использованием или невозможностью использования какого-либо инструмента, функции или всего Сайта.</p>
<p>Администрация не получает от Пользователя персональные данные, не обрабатывает персональные данные Пользователя и не передает персональные данные Пользователя третьим лицам.</p>
<p>Ответственность за сохранность реквизитов платежной карты Пользователя несет оператор платежной системы, осуществляющий платежи в рамках расчетов по Договору. При оплате с помощью банковской карты Администрации не передается информация о держателе карты и другие реквизиты, кроме уведомления об оплате.</p>
<p>Пользователю не предоставляются какие-либо имущественные интеллектуальные права в отношении Сайта, по лицензии либо иным образом.</p>
<p>Пользователь принимает на себя ответственность и все риски, связанные с использованием Сайта. Пользователи самостоятельно несут ответственность за законность любых собственных действий в связи с размещением и хранением информации с использованием Сайта.</p>
<p>Пользователь соглашается с тем, что не считается агентом, представителем или работником Администрации, и что Пользователь не должен представляться таковым.</p>
<p>Пользователь не вправе переуступать свои права и обязанности по настоящему Пользовательскому соглашению полностью или частично. Администрация вправе переуступить любые свои права и обязанности по Пользовательскому соглашению без согласия Пользователя в любое время.</p>
<p>Администрация принимает все разумные меры для обеспечения функционирования и работы Сайта и принимает все зависящие от нее меры для восстановления работоспособности Сайта в случае технических сбоев, перерывов, несанкционированного доступа, иных незаконных действий третьих лиц и негативных обстоятельств, произошедших в сфере предоставления Администрации услуг электросвязи и электроснабжения.</p>
<p>Администрация ни при каких обстоятельствах не несет ответственность за любое неисполнение обязательств, возникающее вследствие причин, объективно не поддающихся ее контролю, в том числе без ограничений: стихийных бедствий, пандемий, эпидемий, войн, терроризма, восстаний, эмбарго, действий гражданских или военных властей, пожаров, наводнений, цунами, извержений вулкана, удара молнии, аварий, забастовок или недостатка транспортных средств, топлива, энергии, рабочей силы или материалов.</p>
<p>Настоящее Пользовательское соглашение регулируется и толкуется в соответствии с действующим законодательством Российской Федерации.</p>
<p>Для разрешения спора или запроса технической поддержки Пользователь вправе направить жалобу или иное обращение через контактные данные, указанные на Сайте.</p>
<p>Администрация оставляет за собой право изменять и дополнять настоящее Пользовательское соглашение в целях обеспечения соответствия изменениям законодательства и для целей улучшения Сайта.</p>
<p>Информация об изменениях и дополнениях Пользовательского соглашения публикуется на Сайте.</p>
<p>Соглашение действует бессрочно до замены его новой версией.</p>
<p>Настоящее Пользовательское соглашение вступает в силу непосредственно в момент принятия его условий Пользователем, и продолжает действовать в полной силе до момента прекращения использования Сайта Пользователем.</p>
<p>Пользователь обязуется периодически посещать веб-страницу Сайта, на которой размещается актуальная версия Пользовательского соглашения.</p>
<p>Продолжая использовать Сайт после изменений в Пользовательское соглашение, Пользователь подтверждает свое согласие с внесенными в него изменениями и принятие его положений.</p>
<p>При внесении изменений в актуальной редакции Пользовательского соглашения указывается дата последнего обновления, даты и адреса размещения в сети Интернет предыдущих редакций Пользовательского соглашения.</p>
<p>Новая редакция Пользовательского соглашения вступает в силу с момента ее размещения на Сайте, если иное не предусмотрено текстом новой редакцией Пользовательского соглашения.</p>
<p>Пользователь может получить любые разъяснения по интересующим вопросам, касающимся использования Сайта, обратившись к Администрации по указанным ниже реквизитам.</p>

<h3>КОНТАКТНАЯ ИНФОРМАЦИЯ И РЕКВИЗИТЫ АДМИНИСТРАЦИИ</h3>
<p>Межевой Алексей Павлович<br>
ИНН: 3804 1446 7293<br>
ОГРН: 324547600111498<br>
Расч. счет: 4080 2810 2200 0035 5803<br>
Банк: ООО "Банк Точка"<br>
БИК: 044 525 104<br>
Корр. счет: 3010 1810 7453 7452 5104<br>
E-mail: iyrishi@mail.ru</p>

<h3>ТЕКУЩАЯ И ПРЕДЫДУЩИЕ РЕДАКЦИИ ПОЛЬЗОВАТЕЛЬСКОГО СОГЛАШЕНИЯ</h3>
<p>Дата утверждения актуальной редакции Соглашения: 28.06.2024<br>
Дата вступления в силу актуальной редакции Соглашения: 28.06.2024<br>
Адрес размещения в сети Интернет актуальной редакции Оферты: https://9avalon.ru</p>
`;

export default agreementText;
