import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import snowflakeImage from './snowflake2.png';
import './snowflakes.css';

const SnowflakeContext = createContext();

export const SnowflakeProvider = ({ children }) => {
  const [isSnowing, setIsSnowing] = useState(true);
  const isInitialized = useRef(false);
  const snowflakesContainer = useRef(null);

  useEffect(() => {
    const container = document.createElement('div');
    container.id = 'snowflakes-container';
    container.style.display = 'none'; // Начальное состояние - скрытые снежинки
    document.body.appendChild(container);
    snowflakesContainer.current = container;

    let interval;
    let snowflakeCount = 0;
    const maxSnowflakesDesktop = 30;
    const maxSnowflakesMobile = 10;

    function createSnowflake() {
      const snowflake = document.createElement('div');
      snowflake.className = 'snowflake';
      snowflake.style.left = `${Math.random() * 100}vw`;
      snowflake.style.width = `${Math.random() * 10 + 20}px`;
      snowflake.style.height = snowflake.style.width;
      snowflake.style.animationDuration = `${Math.random() * 5 + 3}s`;
      snowflake.style.setProperty('--end-x', `${(Math.random() * 2 - 1) * 80}px`);
      snowflake.style.backgroundImage = `url(${snowflakeImage})`;

     

      return snowflake;
    }

    function updateSnowflakeCount() {
      const isMobile = window.innerWidth < 768;
      const maxSnowflakes = isMobile ? maxSnowflakesMobile : maxSnowflakesDesktop;

      if (snowflakeCount < maxSnowflakes && isSnowing) {
        const snowflake = createSnowflake();
        snowflakesContainer.current.appendChild(snowflake);
        snowflakeCount++;
      }
    }

    function startSnowflakeCreation() {
      stopSnowflakeCreation();
      if (isSnowing) {
        snowflakesContainer.current.style.display = 'block';
        interval = setInterval(updateSnowflakeCount, 500);
      }
    }

    function stopSnowflakeCreation() {
      if (interval) clearInterval(interval);
    }

    if (isInitialized.current) {
      if (isSnowing) {
        startSnowflakeCreation();
      } else {
        stopSnowflakeCreation();
      }
    } else {
      isInitialized.current = true;
      startSnowflakeCreation();
    }

    window.addEventListener('resize', startSnowflakeCreation);
    window.addEventListener('beforeunload', stopSnowflakeCreation);

    return () => {
      window.removeEventListener('resize', startSnowflakeCreation);
      window.removeEventListener('beforeunload', stopSnowflakeCreation);
      stopSnowflakeCreation();
      snowflakesContainer.current.remove();
    };
  }, [isSnowing]);

  const toggleSnowflakes = () => {
    setIsSnowing(prev => !prev);
  };

  return (
    <SnowflakeContext.Provider value={{ isSnowing, toggleSnowflakes }}>
      {children}
    </SnowflakeContext.Provider>
  );
};

export const useSnowflakes = () => useContext(SnowflakeContext);
