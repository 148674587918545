import React, { useState, useEffect } from 'react';
import './css/formpage.css';

const FormPage = () => {
    const [amount, setAmount] = useState('');
    const [isFocused, setIsFocused] = useState(false);
    const [isAmountEntered, setIsAmountEntered] = useState(false);

    useEffect(() => {
        setTimeout(() => {
          const content = document.querySelector('.form-page');
          if (content) {
            content.classList.add('content-visible');
          }
        }, 50); // Добавляем небольшую задержку для плавного появления
      }, []);

    useEffect(() => {
        if (!localStorage.getItem('formVisitTracked')) {
            registerVisit();
            localStorage.setItem('formVisitTracked', 'true');
        }
    }, []);

    const registerVisit = async () => {
        try {
            console.log('Registering visit');
            await fetch('https://9avalon.ru/api/payment/track-visit', { method: 'POST' });
        } catch (error) {
            console.error('Ошибка при регистрации посещения: ', error);
        }
    };

    const calculateGlowIntensity = (amount) => {
        const num = parseInt(amount, 10);
        if (!num) return { intensity: 0, shadowSize: '0px' };
        if (num < 100) return { intensity: 0.3, shadowSize: '20px' };
        else if (num < 500) return { intensity: 0.5, shadowSize: '30px' };
        else if (num < 1000) return { intensity: 0.6, shadowSize: '45px' };
        else if (num < 5000) return { intensity: 0.8, shadowSize: '60px' };
        else return { intensity: 1, shadowSize: '75px' };
    };

    const { intensity, shadowSize } = calculateGlowIntensity(amount);

    const handleInputChange = (e) => {
        const value = e.target.value.replace(/[^0-9]/g, '');
        if (value === "" || parseInt(value, 10) === 0) {
        const inputElement = document.querySelector('.form-input');
        inputElement.classList.add('fade-out');
        inputElement.classList.remove('fade-in');
        setTimeout(() => {
            setAmount("");
            setIsAmountEntered(false);
            inputElement.classList.add('fade-in');
            inputElement.classList.remove('fade-out');
        }, 300); // Delay to allow for fade-out effect
    } else {
        setAmount(value);
        setIsAmountEntered(true);
    }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        await handlePayment(amount, 'Поддержка Авалона');
    };

    const handlePayment = async (amount, description) => {
        try {
            const response = await fetch('https://9avalon.ru/api/payment/create-payment', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    amount: amount,
                    description: description,
                }),
            });

            const { confirmation_token, payment_id } = await response.json();

            if (window.checkout) {
                window.checkout.destroy();
            }

            const checkout = new window.YooMoneyCheckoutWidget({
                confirmation_token: confirmation_token,
                error_callback: function (error) {
                    console.error('Ошибка инициализации виджета:', error);
                },
                customization: {
                    modal: true,
                    colors: {
                        control_primary: '#C71585',
                        background: '#FAEBD7'
                    }
                },
            });

            window.checkout = checkout;

            checkout.on('success', async () => {
                await confirmPayment(amount, description, payment_id);
                checkout.destroy();
                window.location.href = 'https://9avalon.ru/conclusion';
            });

            checkout.on('fail', () => {
                console.error('Платеж не удался');
                checkout.destroy();
            });

            checkout.render('payment-form');
        } catch (error) {
            console.error('Ошибка при создании платежа:', error);
        }
    };

    const confirmPayment = async (amount, description, payment_id) => {
        try {
            const response = await fetch('https://9avalon.ru/api/payment/confirm-payment', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    amount: amount,
                    description: description,
                    payment_id: payment_id
                }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            console.log('Payment confirmed');
        } catch (error) {
            console.error('Error confirming payment:', error);
        }
    };

    const handlePresetAmountClick = (presetAmount) => {
        const inputElement = document.querySelector('.form-input');
        if (amount === presetAmount.toString()) {
            inputElement.classList.add('fade-out');
            inputElement.classList.remove('fade-in');
            setTimeout(() => {
            setAmount('');
            setIsAmountEntered(false);
            inputElement.classList.remove('fade-out');
            document.activeElement.blur(); 
            }, 300); 
        } else {
            inputElement.classList.add('fade-out');
            inputElement.classList.remove('fade-in');
            setTimeout(() => {
            setAmount(presetAmount.toString());
            setIsAmountEntered(true);
            inputElement.classList.add('fade-in');
            inputElement.classList.remove('fade-out');
            }, 300); 
        }
    };

    useEffect(() => {
        const inputElement = document.querySelector('.form-input');
        if (inputElement) {
          inputElement.classList.add('transition-input');
        }
      }, []);

    return (
        <div className="form-page content-hidden">
            <div className="form-background" style={{
                '--intensity': intensity,
                '--shadow-size': shadowSize
            }}>
                <form onSubmit={handleSubmit} className="form-container">
                    <div id="payment-form"></div>
                    <div className="input-wrapper">
                        <input
                            type="text"
                            value={amount}
                            onChange={handleInputChange}
                            onFocus={() => setIsFocused(true)}
                            onBlur={() => setIsFocused(false)}
                            required
                            className="form-input"
                            placeholder={isFocused ? '' : 'Сумма вашего дара'}
                            inputMode="numeric"
                        />
                    <span className={`currency-symbol ${amount ? 'visible' : 'hidden'}`}>₽</span>
                    </div>
                    <div className="separator"></div>
                    <button
                        type="submit"
                        className={`form-button ${isAmountEntered ? 'visible amount-entered' : 'invisible'}`}
                        disabled={!isAmountEntered}
                    >
                        Оставить след
                    </button>
                </form>
            </div>
            <div className="preset-amounts">
                        {[100, 500, 1000, 5000].map(presetAmount => (
                            <button
                                key={presetAmount}
                                type="button"
                                className="preset-amount-button"
                                onClick={() => handlePresetAmountClick(presetAmount)}
                            >
                                {`${presetAmount}₽`}
                            </button>
                        ))}
            </div>
        </div>
    );
};

export default FormPage;
