// src/preloader/preloader.js
import React, { useEffect } from 'react';
import './preloader.css';
import { ReactComponent as Deer} from './deer-svgrepo-com.svg'; 


const Preloader = ({ setLoading }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      document.body.classList.add('loaded_hiding');
      setTimeout(() => {
        setLoading(false);
        document.body.classList.add('loaded');
        document.body.classList.remove('loaded_hiding');
      }, 1000); // Время для плавного исчезновения прелоадера
    }, 3000); // Время отображения прелоадера

    return () => clearTimeout(timer);
  }, [setLoading]);

  return (
    <div className="preloader">
      <div className="preloader">
          <Deer className="preloader__deer" />
    </div>
    </div>
  );
};

export default Preloader;
