// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';

import HomePage from './pages/HomePage';
import FormPage from './pages/FormPage';
import ConclusionPage from './pages/ConclusionPage';
import AdminPanel from './pages/AdminPanel';

import { SnowflakeProvider, useSnowflakes } from './assets/snowflakes/snowflakes';

import Footer from './pages/Footer';
import Header from './pages/header';

import Preloader from './assets/preloader/preloader';

import AgreementPopup from './pages/css/components/agreementPopup/agreementPopup';

import ProgressBar from './pages/ProgressBar';

import snowflakeIcon from './assets/snowflakes/snowflake2.png';

import './assets/css/App.css';


const App = () => {
  const location = useLocation();

  const { isSnowing, toggleSnowflakes } = useSnowflakes(); 
  const [loading, setLoading] = useState(true);

  const [isAgreementAccepted, setIsAgreementAccepted] = useState(
    localStorage.getItem('agreementAccepted') === 'true'
  );


  useEffect(() => {
    if (!loading && isAgreementAccepted) {
      const content = document.querySelector('.content');
      if (content) {
        content.classList.add('content-visible');
      }
      if (!localStorage.getItem('visitTracked')) {
        trackVisit();
        localStorage.setItem('visitTracked', 'true');
      }
    }
  }, [loading, isAgreementAccepted]);


  const handleAccept = () => {
    localStorage.setItem('agreementAccepted', 'true');
    setIsAgreementAccepted(true);
  };

  const handleDecline = () => {
    localStorage.setItem('agreementAccepted', 'false');
    setIsAgreementAccepted(false);
  };

  const trackVisit = async () => {
    try {
      await fetch('http://9avalon.ru/api/payment/track-visit', { method: 'POST' });
    } catch (error) {
      console.error('Ошибка при отслеживании посещения:', error);
    }
  };


  if (!isAgreementAccepted) {
    return <AgreementPopup onAccept={handleAccept} onDecline={handleDecline} />;
  }

  const isAdminPage = location.pathname === '/admin';

  return (
    <>
      {loading && isAgreementAccepted && <Preloader setLoading={setLoading} />}
      {!loading && isAgreementAccepted && (
        <div className={`content ${loading ? 'content-hidden' : 'content-visible'}`}>
          {!isAdminPage && (
            <>
              <Header />
              <ProgressBar />
            </>
          )}
            <Routes location={location}>
              <Route path="/" element={<HomePage />} />
              <Route path="/form" element={<FormPage />} />
              <Route path="/conclusion" element={<ConclusionPage />} />
              <Route path="/admin" element={<AdminPanel />} />
            </Routes>
          {!isAdminPage && <Footer />}
        </div>
      )}
      <div className="icons-container">
        <img
          src={snowflakeIcon}
          alt="Toggle Snowflakes"
          className="icon"
          onClick={() => {
            console.log("Snowflake toggle clicked");
            toggleSnowflakes();
          }}
        />
      </div>
    </>
  );
};


const AppWrapper = () => (
  <SnowflakeProvider>
    <Router>
      <App />
    </Router>
  </SnowflakeProvider>
);

export default AppWrapper;