import React, {useEffect} from 'react';
import './css/conclusionpage.css';

const ConclusionPage = () => {
  useEffect(() => {
    setTimeout(() => {
      const content = document.querySelector('.conclusion-page');
      if (content) {
        content.classList.add('content-visible');
      }
    }, 100); // Добавляем небольшую задержку для плавного появления
  }, []);

  return (
    <div className="conclusion-page content-hidden">
      <h1 className="thanks-text">Спасибо, дитя</h1>
    </div>
  );
};

export default ConclusionPage;
